import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import Post from "../components/Post";
import styled from "styled-components";
import { truncateLong } from "../utils/helpers";

const H2 = styled.h2`
  font-family: "Space Mono", monospace;
  font-size: 16rem;
  font-weight: 700;
  color: var(--indigo2);
  height: 24rem;
  padding: 9rem 0 0 0;
  margin: 0 0 24rem 0;
  line-height: 16rem;
`;

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"} tagged `;

  return (
    <Layout title={`Posts tagged ${tag} • Interaction Compendium`}>
      <H2>
        {tagHeader} “{tag}”
      </H2>
      {edges.map((edge) => {
        const {
          title,
          slug,
          date,
          tags,
          product_link,
          product_link_name,
          featured_video,
          featured_video_id,
          featured_video_start,
          featured_video_host,
          referrer_url,
          referrer_name,
          author,
        } = edge.node.frontmatter;
        return (
          <Post
            key={slug + title}
            slug={slug}
            date={date}
            title={title}
            tags={tags}
            product_link={product_link}
            product_link_name={product_link_name}
            featured_video={featured_video}
            featured_video_id={featured_video_id}
            featured_video_start={featured_video_start}
            featured_video_host={featured_video_host}
            referrer_url={referrer_url}
            referrer_name={referrer_name}
            author={author}
            html={truncateLong(edge.node.html)}
          />
        );
      })}
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            slug
            date(formatString: "DD MMMM, YYYY")
            title
            featured_video
            featured_video_id
            featured_video_start
            featured_video_host
            tags
            author
            product_link
          }
        }
      }
    }
  }
`;
